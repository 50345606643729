import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { joinString, capitalize } from "../../helper/join";
import { useSelector } from "react-redux";

export const SideBar = ({ open, token }) => {
  const [selected, setSelected] = useState([]);
  const location = useLocation();
  const { userInfo } = useSelector((store) => store.login);

  const Tabs = {
    offerings: [
      { name: "Courses", route: "/offerings/courses" },
      { name: "Events", route: "/offerings/events" },
      { name: "Products", route: "/offerings/products" },
    ],
    "Payment Request": [
      { name: "Vouchers", route: "/payment-request/view-voucher" },
      { name: "Emails", route: "/payment-request/all-emails" },
      // { name: "Pending", route: "/payment-request/pending" },
      // { name: "Processing", route: "/payment-request/processing" },
      // { name: "Paid", route: "/payment-request/paid" },
      { name: "Orders", route: "/payment-request/orders" },
      // { name: "Event Response", route: "/payment-request/event-response" },
    ],
    Users: [
      { name: "Active Users", route: "/users/list/1" },
      { name: "Inactive Users", route: "/users/list/0" },
      { name: "Admin Users", route: "/users/admin-user-roles" },
      { name: "Discussions", route: "/users/overall" },
    ],
    tickets: [{ name: "View All", route: "/tickets/view-all" }],
    Others: [
      { name: "Action List", route: "/others/action-list" },
      { name: "Quote Requests", route: "/others/quote-requests" },
      { name: "Join Research Requests", route: "/others/request-join-research" },
      { name: "Expert Hiring Form", route: "/others/expert-hiring-form" },
      { name: "Newsletters", route: "/others/newsletters" },
      // { name: "Reports", route: "/others/reports" },
      { name: "Reports", route: "/others/report-abuse" },
      { name: "Error Logs", route: "/others/error-logs" },
    ],
    Contents: [
      { name: "Team", route: "/contents/team" },
      { name: "Trainers", route: "/contents/trainers" },
      { name: "Case Studies", route: "/contents/case-studies" },
      { name: "Blogs", route: "/contents/blogs" },
      { name: "Faqs", route: "/contents/faqs" }
    ],
    Jobs: [
      { name: "View All", route: "/jobs/view-all" },
      { name: "All Applications", route: "/jobs/all-applications" },
      { name: "Job Filters Update", route: "/jobs/filter" },
      { name: "Companies", route: "/jobs/companies" },
      { name: "Candidate Req.", route: "/jobs/candidate-request" },
      { name: "Employer Req.", route: "/jobs/employee-request" },
    ],
    Buyer: [
      { name: "Knowledge Bank", route: "/buyer/knowledge-bank" },
    ],
    // "Influencers": [
    //   { name: "Profiles", route: "/influencers/profiles" },
    //   { name: "Posted Content", route: "/influencers/posted-content" },
    //   // { name: "Filters", route: "/influencers/filters" },
    //   //{ name: "Price Tag Settings", route: "/influencers/price-setting" },
    //   {name: "System Settings", route: "/influencers/system-settings"}
    // ],
    "Companies": [
      {name: "All", route: "/companies/all"},
      {name: "Queries", route: "/companies/queries"},
      {name: "Filters", route: "/companies/filters"}
    ]
    // "Buying Simplified": [
    //   { name: "Hierarchy Chart", route: "/bs/hierarchy-chart" },
    //   { name: "Policies", route: "/bs/policies" },
    // ]
  };

  return (
    <div>
      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-16 z-40 w-56 ${
          (open && token) ? "left-0" : "-left-56"
        } h-screen transition-all duration-300 ease-in-out overflow-auto bg-gray-100 pb-20 border-2`}
        aria-label="Sidebar"
      >
        <div className="px-3 py-4 overflow-none">
          <ul className="space-y-0.5">
            {(userInfo?.ability === "Super-Admin"
              ? Object.keys(Tabs)
              : Object.keys(Tabs).slice(0, 1)
            ).map((tab, index) => {
              if (location.pathname.includes(`/${joinString(tab)}`)) {
                document.title = `Control Panel | ${capitalize(tab)}`;
              }
              return (
                <li key={index}>
                  <button
                    type="button"
                    className={`flex items-center w-full px-2 py-1 text-base transition rounded-full duration-200 ease-out group hover:bg-orange-500 hover:text-white ${
                      location.pathname.includes(`/${joinString(tab)}`) || selected?.includes(tab)
                        ? "bg-orange-500 text-white"
                        : null
                    }`}
                    onClick={() => {
                      if (selected?.includes(tab)) {
                        setSelected(selected?.filter((sel) => sel !== tab));
                      } else {
                        setSelected([...selected, tab]);
                      }
                    }}
                  >
                    {!selected?.includes(tab) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                    <span className="flex-1 ms-1 text-[16px] text-left rtl:text-right whitespace-nowrap capitalize">
                      {tab}
                    </span>
                  </button>
                  <ul
                    id="dropdown-example"
                    className={`${
                      selected?.includes(tab) ? null : "hidden"
                    } py-1 space-y-0.5 transition-all duration-200 ease-in-out`}
                  >
                    {Tabs[tab]?.map((item, ind) => {
                      return (
                        <li key={ind} className="ml-3">
                          <Link
                            to={item?.route}
                            className={`flex items-center w-full px-2 py-1 text-gray-900 text-[14px] transition rounded-full duration-200 pl-11 group hover:bg-orange-300 ${
                              location.pathname.includes(item?.route)
                                ? "bg-orange-300"
                                : null
                            }`}
                          >
                            {item.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </aside>
    </div>
  );
};
