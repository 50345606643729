import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSetting, updateSettings } from "../../../redux/courses.ts";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/loader/index.js";
import { UsersList } from "../../../components/usersList.js/index.js";
import { RxCross1 } from "react-icons/rx";

export const Settings = () => {
	const { title, type } = useParams();
	const dispatch = useDispatch();
	const [setting, setSetting] = useState({});
	const { settings, isLoading } = useSelector((store) => store.course);
	const [userList, setUserList] = useState([]);
	const [userInfo, setUserInfo] = useState();

	useEffect(() => {
		dispatch(fetchSetting(title));
	}, []);

	useEffect(() => {
		setUserList(settings?.users);
		setSetting({
			"Manager can enroll": settings?.course_setting?.manager_can_enroll,
			[`${type?.slice(0, -1)} Rating`]: settings?.course_setting?.course_rating,
			"Email on review": settings?.course_setting?.email_on_review,
			"Disable instructor message":
				settings?.course_setting?.disable_instructor_message,
			[`Allow Unenroll ${type?.slice(0, -1)}`]:
				settings?.course_setting?.allow_unenroll_course,
			"Algorithm": settings?.course_setting?.algorithm,
		});
	}, [settings]);

	useEffect(() => {
		if (userInfo?.userId) {
			setUserList([
				...userList,
				{ id: userInfo?.userId, userName: userInfo?.userName },
			]);
		}
	}, [userInfo]);

	if (isLoading?.settings) {
		return (
			<div className="flex justify-center">
				<Loader w="20" h="20" color="orange" />
			</div>
		);
	}

	const handleUpdate = () => {
		if (userList?.length !== 0) {
			const updatedSettings = {
				slug: title,
				manager_can_enroll: setting["Manager can enroll"] ?? false,
				course_rating: setting["Course Rating"] ?? false,
				email_on_review: setting["Email on review"] ?? false,
				disable_instructor_message:
					setting["Disable instructor message"] ?? false,
				allow_unenroll_course: setting["Allow Unenroll Course"] ?? false,
				algorithm: setting["Algorithm"] ?? false,
				users: userList?.map((user) => {
					return user?.id;
				}),
			};
			dispatch(updateSettings(updatedSettings));
		}
	};

	return (
		<div>
			<div className="grid grid-cols-2 border-2 border-gray-300 pb-6">
				<div className="bg-gray-200 col-start-1 col-span-3 mb-5 py-4 border border-gray-300">
					<span className="ml-5 text-bold">Additional Setting</span>
				</div>
				<div className="relative col-start-1 md:col-span-1 z-20 w-full group">
					<label className="ml-5 col-start-1 col-span-1 mb-3 font-light">
						Selected Users
					</label>
					<div className="ml-5 mb-2 mt-3">
						<UsersList setUserInfo={setUserInfo} userList={userList} />
						{userList?.length === 0 && (
							<div className="text-red-600 text-sm">
								Atleast one user is required!
							</div>
						)}
					</div>
				</div>
				<div className="col-start-1 col-span-2 gap-2 ml-5 mb-4 flex flex-wrap flex-start mt-1">
					{userList?.map((user, index) => {
						return (
							<div
								key={index}
								className="text-xs flex justify-between shrink-0 border rounded-xl shadow-md p-2 text-center"
							>
								{user?.userName ?? `${user?.firstname} ${user?.lastname}`}
								<button
									onClick={() => {
										setUserList(
											userList?.filter((users) => {
												return users?.id !== user?.id;
											})
										);
									}}
									className="ml-2 text-red-600"
								>
									<RxCross1 size={12} />
								</button>
							</div>
						);
					})}
				</div>
				{Object?.keys(setting)?.map((set, index) => {
					return (
						<div
							key={index}
							className="flex justify-between items-center col-span-3 w-3/4"
						>
							<div className="ml-5 mb-3 capitalize">{set}</div>
							<div className="relative inline-flex items-center cursor-pointer mb-3">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										checked={setting[set]}
										onChange={() =>
											setSetting({
												...setting,
												[set]: setting[set] === 0 ? 1 : 0,
											})
										}
										value=""
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-600"></div>
								</label>
							</div>
						</div>
					);
				})}
			</div>
			<div className="mt-5">
				<button
					onClick={handleUpdate}
					className="border-orange-600 capitalize text-orange-600 hover:bg-orange-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
				>
					{isLoading?.settingUpdate ? (
						<Loader w="8" h="8" color="orange" />
					) : (
						"Update"
					)}
				</button>
			</div>
		</div>
	);
};
