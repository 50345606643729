import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "../../../components/modal";
import { CiImport } from "react-icons/ci";

const View = ({ type }, ref) => {
	const [show, setShow] = useState(false);
	const [data, setData] = useState({});

	useImperativeHandle(ref, () => ({
		open(data) {
			setShow(true);
			setData(data);
		},
	}));

	return (
		<Modal size={"md"} show={show} setShow={setShow}>
			{type === "report" ? (
				<div className="flex justify-start flex-col items-start gap-y-2">
					<h1
						style={{ wordBreak: "break-word" }}
						className="text-lg font-semibold"
					>
						Reason To Report
					</h1>
					<div
						style={{ wordBreak: "break-word" }}
						className="text-lg font-light"
					>
						{data?.reason || "No reason was provided!"}
					</div>
					<h1
						style={{ wordBreak: "break-word" }}
						className="text-lg font-semibold"
					>
						{!!data?.answer
							? "Answer Details:"
							: !!data?.question
							? "Question Details:"
							: "Comment Details:"}
					</h1>
					<div
						style={{ wordBreak: "break-word" }}
						className="text-lg font-light"
					>
						<div
							dangerouslySetInnerHTML={{
								__html: !!data?.answer
									? data?.answer
									: !!data?.question
									? data?.question
									: data?.comment,
							}}
						/>
					</div>
				</div>
			) : (
				<div className="flex justify-start flex-col items-start gap-y-2">
					<h1
						style={{ wordBreak: "break-word" }}
						className="text-lg font-semibold"
					>
						{data?.user_name}
					</h1>

					<h1
						style={{ wordBreak: "break-word" }}
						className="text-lg font-light"
					>
						Email: {data?.user_email}
					</h1>
					{!(
						type === "rfq" ||
						type === "request-join-research" ||
						type === "expert-hiring-form"
					) && (
						<h1
							style={{ wordBreak: "break-word" }}
							className="text-lg font-light"
						>
							Ticket Type: {data?.ticket_type}
						</h1>
					)}
					{type !== "expert-hiring-form" && (
						<h1
							style={{ wordBreak: "break-word" }}
							className="text-lg font-light"
						>
							{type === "rfq" || type === "request-join-research"
								? "Company"
								: "Reason"}
							: {data?.reason}
						</h1>
					)}
					<h1
						style={{ wordBreak: "break-word" }}
						className="text-lg font-semibold"
					>
						{type === "rfq" ||
						type === "request-join-research" ||
						type === "expert-hiring-form"
							? "Details"
							: "Description"}
						:
					</h1>
					{type === "request-join-research" ? (
						<div className="flex justify-start items-start flex-col gap-3">
							<div className="flex justify-start items-start gap-3">
								<div>Phone Number:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.others?.[0]?.phoneNumber}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Qualification:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.others?.[0]?.degree}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Current Position :</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.description}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Relevant Experience:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.others?.[0]?.prevExperience}
								</div>
							</div>
							<a
								href={data?.others?.[0]?.file}
								target="_blank"
								download
								rel="noreferrer"
								className={`shrink-0 py-2 transition-all duration-200 ease-in-out text-sm gap-x-3 px-3 text-white bg-orange-600 rounded-full flex justify-center hover:opacity-70 hover:scale-105`}
							>
								<CiImport size={20} />
								Download CV
							</a>
						</div>
					) : type === "expert-hiring-form" ? (
						<div className="flex justify-start items-start flex-col gap-3">
							<div className="flex justify-start items-start gap-3">
								<div>Expertise:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.description}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Additional Comments:</div>
								<div style={{ wordBreak: "break-word" }}>{data?.reason}</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Date Range:</div>
								<div>
									{data?.others?.[0]?.dateRange?.[0]?.slice(0, 10)} -{" "}
									{data?.others?.[0]?.dateRange?.[1]?.slice(0, 10)}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Hourly Rate:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.others?.[0]?.hourlyRate}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Web Portofolio:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.others?.[0]?.portfolioWeb || "Not Specified!"}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Previous Experience:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.others?.[0]?.prevExperience}
								</div>
							</div>
							<div className="flex justify-start items-start gap-3">
								<div>Phone Number:</div>
								<div style={{ wordBreak: "break-word" }}>
									{data?.others?.[0]?.phoneNumber}
								</div>
							</div>
							<a
								href={data?.others?.[0]?.file}
								target="_blank"
								download
								rel="noreferrer"
								className={`shrink-0 py-2 transition-all duration-200 ease-in-out text-sm gap-x-3 px-3 text-white bg-orange-600 rounded-full flex justify-center hover:opacity-70 hover:scale-105`}
							>
								<CiImport size={20} />
								Download CV
							</a>
						</div>
					) : (
						<div
							style={{ wordBreak: "break-word" }}
							className="text-lg font-light"
						>
							{data?.description ||
								(type === "rfq"
									? "No Purpose/Requirement Details provided!"
									: "No description provided!")}
						</div>
					)}
				</div>
			)}
		</Modal>
	);
};

export default forwardRef(View);
