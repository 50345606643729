import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportAbuse } from "../../redux/others.ts";
import { Pagination } from "../../components/pagination/index.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import View from "../actionList/View/index.jsx";

export const ReportAbuse = () => {
	const dispatch = useDispatch();
	const modalRef = useRef(null);
	const { reportAbuseList, isLoading } = useSelector((store) => store.other);

	useEffect(() => {
		dispatch(fetchReportAbuse(1));
	}, []);

	function handlePage(page) {
		dispatch(fetchReportAbuse(page));
	}

	const tableData = [
		{ value: "reason", to: `` },
		{ key: "Reported By", type: "fullname" },
		{ key: "Report Against", value: "course_status" },
		{ key: "Report Date", value: "created_at" },
	];

	const handleView = (a, b, tab) => {
		modalRef.current.open(tab);
	};

	const actions = [{ type: "view", func: handleView, isLoading: false }];

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Reports", link: "" }]} />
			<View type={"report"} ref={modalRef} />
			<div className="mx-10 relative">
				<div className="h-[calc(100vh-180px)] overflow-auto my-2 hide-scroll">
					<Table
						data={tableData}
						table={reportAbuseList}
						isLoading={isLoading?.reportAbuse}
						actions={actions}
					/>
				</div>
				<Pagination
					current={reportAbuseList?.current_page}
					func={handlePage}
					total={reportAbuseList?.last_page}
				/>
			</div>
		</>
	);
};
