import { useEffect, useRef, useState } from "react";
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Transition,
} from "@headlessui/react";
import { TopicTable } from "./topicTable";
import { AddBtn } from "../../../components/addBtn";
import AddUpdateTopic from "../chapters/chapterModal/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
	addCourseChapter,
	deleteChapter,
	fetchCourseChapters,
} from "../../../redux/courses.ts";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/loader/index.js";
import DeleteModal from "../../../components/deleteModal/index.js";

export const Chapters = () => {
	const [addChap, setAddChap] = useState(false);
	const [editChap, setEditChap] = useState(false);
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const popupRef = useRef(null);
	const { title } = useParams();
	const { chapters, isLoading } = useSelector((store) => store.course);
	const [id, setId] = useState(null);
	const modalRef = useRef();

	useEffect(() => {
		dispatch(fetchCourseChapters(title));
	}, []);

	const handleChapterAdd = (e) => {
		e.preventDefault();
		if (data?.newChap) {
			dispatch(
				addCourseChapter(
					{ course_slug: title, title: data?.newChap, type: "chapter" },
					setAddChap,
					() => setData({ ...data, newChap: "" })
				)
			);
		}
	};

	const handleEditBtn = (e, chapter) => {
		e.stopPropagation();
		setEditChap(chapter?.id);
		setData({ ...data, editChap: chapter?.content_name, id: chapter?.id });
	};

	const handleEditChap = (e) => {
		e.preventDefault();
		if (data?.editChap) {
			dispatch(
				addCourseChapter(
					{
						course_slug: title,
						title: data?.editChap,
						content_id: data?.id,
						type: "chapter",
					},
					setEditChap,
					() => setData({ ...data, id: null })
				)
			);
		}
	};

	const handleDeleteChap = (data) => {
		setId(data?.content_id);
		modalRef?.current?.open({
			id: data,
			deleteFun: deleteChapter,
			type: "Chapter",
		});
	};

	const addUpdatePopUp = (data) => {
		popupRef?.current?.open(data);
	};

	if (isLoading?.chapters) {
		return (
			<div className="flex justify-center">
				<Loader w="20" h="20" color="orange" />
			</div>
		);
	}

	const handleKeyDown = (event) => {
		if (event.key === " " && event.target.tagName === "INPUT") {
			setData({ ...data, editChap: `${data?.editChap} ` });
			event.preventDefault();
		} else if (event.key === "Enter" && event.target.tagName === "INPUT") {
			handleEditChap(event);
		}
	};

	return (
		<div>
			<DeleteModal ref={modalRef} />
			<AddUpdateTopic ref={popupRef} />
			<div className="flex flex-col justify-start items-start gap-y-3 w-full">
				<div className="flex flex-col gap-y-5 justify-start items-start w-full">
					{addChap ? (
						<form
							onSubmit={handleChapterAdd}
							className="flex justify-center items-center gap-x-4"
						>
							<div className="mx-5 space-y-3">
								<input
									onChange={(e) =>
										setData({ ...data, newChap: e.target.value })
									}
									placeholder="Input New Chapter Name"
									value={data?.newChap || ""}
									type="text"
									maxLength={100}
									autoFocus
									className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
									required
								/>
							</div>
							<button
								type="button"
								onClick={() => setAddChap(false)}
								className="border-red-600 capitalize text-red-600 hover:bg-red-600 bg-white px-3 py-1 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
							>
								Cancel
							</button>
							<button
								type="submit"
								className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-1 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
							>
								{isLoading?.addChap && !data?.id ? (
									<Loader w={"6"} h={"6"} color={"blue"} />
								) : (
									"Add"
								)}
							</button>
						</form>
					) : (
						<AddBtn text={"Add New Chapter"} func={() => setAddChap(true)} />
					)}
					{chapters?.map((chapter) => {
						return (
							<Disclosure>
								<DisclosureButton
									as="div"
									className="flex cursor-pointer items-center rounded-md shadow-md justify-between w-full p-3 font-medium rtl:text-right text-orange-500 border border-gray-200 focus:ring-0 gap-3"
								>
									<div>
										{chapter?.id === editChap ? (
											<form
												onSubmit={handleEditChap}
												className="flex justify-center items-center gap-x-4"
											>
												<div className="mx-1 space-y-3">
													<input
														onChange={(e) =>
															setData({ ...data, editChap: e.target.value })
														}
														placeholder="Type Chapter Name"
														value={data?.editChap || ""}
														type="text"
														maxLength={100}
														onKeyDown={handleKeyDown}
														autoFocus
														required
														onClick={(e) => e.stopPropagation()}
														className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-1 w-72"
													/>
												</div>
												<button
													onClick={(e) => {
														e.stopPropagation();
														setEditChap(null);
														setData({ ...data, id: null });
													}}
													type="button"
													className="border-red-600 capitalize text-red-600 hover:bg-red-600 bg-white text-[12px] px-2 py-0.5 border rounded-lg hover:text-white transition-all duration-200 ease-in-out"
												>
													Cancel
												</button>
												<button
													onClick={(e) => e.stopPropagation()}
													type="submit"
													className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 text-[12px] bg-white px-2 py-0.5 border rounded-lg hover:text-white transition-all duration-200 ease-in-out"
												>
													{isLoading?.addChap && data?.id === chapter?.id ? (
														<Loader w={"4"} h={"4"} color={"blue"} />
													) : (
														"Edit"
													)}
												</button>
											</form>
										) : (
											<p className="max-w-2xl truncate">
											{chapter?.content_name}
											</p>
										)}
									</div>
									{chapter?.id !== editChap && (
										<div className="flex justify-center items-center gap-x-4">
											<button
												onClick={(e) => handleEditBtn(e, chapter)}
												className="text-blue-600"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
													/>
												</svg>
											</button>
											<button
												onClick={(e) => {
													e.stopPropagation();
													handleDeleteChap({
														course_id: chapter?.course_id,
														content_id: chapter?.id,
														type: "chapter",
													});
												}}
												className="text-red-600"
											>
												{isLoading?.deleteChapter && chapter?.id === id ? (
													<Loader w={"5"} h={"5"} color={"red"} />
												) : (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-5 h-5"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
														/>
													</svg>
												)}
											</button>
										</div>
									)}
								</DisclosureButton>
								<Transition
									enter="duration-200 ease-out"
									enterFrom="opacity-0 -translate-y-6"
									enterTo="opacity-100 translate-y-0"
									leave="duration-300 ease-out"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 -translate-y-6"
								>
									<DisclosurePanel className={"w-full -mt-5 py-4 px-5 border-t-0 shadow-sm rounded-b-lg"}>
										<div className="flex flex-col justify-start items-start gap-y-3">
											<AddBtn
												text={"Add New Topic"}
												func={() => {
													addUpdatePopUp({
														status: "Add",
														chapter: chapter,
														course_slug: title,
													});
												}}
											/>
											<TopicTable
												topics={chapter?.content_modules || []}
												popupRef={popupRef}
												modalRef={modalRef}
												course_id={chapter?.course_id}
												title={title}
											/>
										</div>
									</DisclosurePanel>
								</Transition>
							</Disclosure>
						);
					})}
				</div>
			</div>
		</div>
	);
};
