import { capitaliz } from "./captalize";

export const joinString = (Str) => Str.split(" ").join("-").toLowerCase();

export const capitalize = (str) => {
	const words = str.split(" ");
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});
	return capitalizedWords.join(" ");
};

export const isEmpty = (obj) => {
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const getMaxLevel = (data) => {
	let maxLevel = 0;

	for (const key in data) {
		if (data[key].level > maxLevel) {
			maxLevel = data[key].level;
		}
	}

	return maxLevel;
};

export const encryptfunction = (id) => {
	return window.btoa(id);
};

export const decryptUrl = (id) => {
	return window.atob(id);
};

export const tableEntry = (tab, dat) => {
	let value = dat?.nested2
		? tab?.[dat?.value1]?.[dat?.value2]?.[dat?.value3] ||
		  (tab?.[dat?.value1]?.[dat?.value2]?.[dat?.value3] == 0 && "0")
		: dat?.nested
		? tab?.[dat?.value1]?.[dat?.value2] ||
		  tab?.[dat?.value11]?.[dat?.value2] ||
		  (tab?.[dat?.value1]?.[dat?.value2] == 0 && "0")
		: tab?.[dat?.value] || dat?.text || (tab?.[dat?.value] == 0 && "0");

	if (dat?.type === "profile_type") {
		if (value == 1) return "Company";
		if (value == 0) return "Individual";
	}

	if (dat?.type === "profile_status") {
		if (value == 1) return "Pending";
		if (value == 2) return "Approved";
		if (value == 3) return "Rejected";
	}

	if (dat?.type === "contentStatus") {
		if (value == 0) return "To Review";
		if (value == 1) return "Approved";
	}

	if (dat?.type === "date-time") {
		const date = new Date(value);
		value = date.toLocaleString("en-US", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: true,
		});
	}

	if (dat?.type === "fullname") {
		return `${tab?.firstname} ${tab?.lastname}`;
	}

	return dat?.capitalize ? capitaliz(value) : value;
};

export const getPlainText = (content) => {
	const tempElement = document.createElement("div");
	tempElement.innerHTML = content;
	return tempElement.innerText || tempElement.textContent || "";
};
