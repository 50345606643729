import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../components/pagination/index.js";
import { fetchEventResponse } from "../../redux/courses.ts";
import { Table } from "../../components/table/index.jsx";
import { useParams } from "react-router-dom";

export const EventResponse = () => {
	const dispatch = useDispatch();
	const { events, isLoading } = useSelector((store) => store.course);
	const { title } = useParams();

	useEffect(() => {
		dispatch(fetchEventResponse({ params: { course_slug: title } }));
	}, []);

	function handlePage(page) {
		dispatch(fetchEventResponse({ params: { page: page } }));
	}

	const tableData = [
		{ type: "fullName", to: `` },
		{ key: "Event", nested: "true", value1: "course", value2: "course_name" },
		{ key: "E-mail", value: "email" },
		{ key: "Registered On", value: "created_at", type: "date-time" },
	];

	return (
		<div className="relative">
			<div className="h-[calc(100vh-230px)] overflow-auto my-2 hide-scroll">
				<Table
					data={tableData}
					table={events}
					isLoading={isLoading?.eventResponse}
				/>
			</div>
			<Pagination
				total={events?.last_page}
				current={events?.current_page}
				func={handlePage}
			/>
		</div>
	);
};
