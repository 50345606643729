import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DraftEditor from "../../components/DraftEditor/index.js";
import { addCaseStudy } from "../../redux/caseStudiesSlice.ts";
import { Loader } from "../../components/loader/index.js";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import { getPlainText } from "../../helper/join.js";
import { toastNotification } from "../../services/notification-service.js";

export const AddCaseStudy = () => {
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [draft, setDraft] = useState();
  const { isLoading } = useSelector((store) => store.caseStudies);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit = 10000

    const handleAdd = (e) => {
    e.preventDefault();
    if (getPlainText(draft)?.length > limit) {
			return toastNotification({status: "error", message: `Description must be under the limit of ${limit}`})
		}
    const formData = new FormData();
      const combinedData = { ...data, conclusion: draft };
      if (!combinedData.title || !combinedData.conclusion || combinedData.conclusion === "<p><br></p>") {
        const errorMessage = !combinedData.title ? "Title is required field." :
          !combinedData.conclusion || combinedData.conclusion === "<p><br></p>" ? "Conclusion is required field." : "";
        setError(errorMessage);
        return;
      }

      Object.keys(combinedData).forEach((key) => {
        formData.append(key, combinedData[key]);
      });
      setError("");
      dispatch(addCaseStudy({ formData: { formData } }, navigate));
  };
  
  return (
    <>
      <Breadcrumbs breadcrumbs={[
          {name: "Case Studies", link: '/contents/case-studies'},
          {name: "Add Case Study", link: ``},
      ]} />
      <div className="bg-white mx-[55px] shadow-lg pb-4 px-6 sm:py-4">
        <form onSubmit={handleAdd} className="flex flex-col justify-center items-center mt-5">
          <div className="w-full mx-auto">
            <div className="grid md:grid-cols-4 md:gap-20">
              <div className="relative md:col-start-1 md:col-span-2  w-full mb-5 group">
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                  placeholder=" "
                  value={data?.title || ''}
                  onChange={(e) =>
                    setData({ ...data, title: e.target.value })
                  }
                  required
                />
                <label
                  htmlFor="title"
                  className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Title *
                </label>
              </div>
              <div className="md:col-start-3 md:col-span-2 relative  w-full mb-5 group">
                <input
                  type="text"
                  name="subtitle"
                  id="subtitle"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                  placeholder=" "
                  value={data?.sub_title || ''}
                  onChange={(e) =>
                    setData({ ...data, sub_title: e.target.value })
                  }
                  required
                />
                <label
                  htmlFor="subtitle"
                  className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Sub Title *
                </label>
              </div>
            </div>
            <div>
            <div className="text-sm text-orange-600 mb-3">
              Conclusion *
            </div>
            <div className="relative  w-full mb-5 group border border-black">
              <DraftEditor onChange={setDraft} limit={limit} />
            </div>
            </div>
            <button
              className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
            >
              {isLoading?.addCaseStudy ? (
              <div>
                <Loader h="6" w="6" color={"blue"} />
              </div>
            ) : (
              "Add"
            )}
            </button>
            {error && <p className="my-2 text-red-500">{error}</p>}
          </div>
        </form>
      </div>
    </>
  );
};
