import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getPlainText } from "../../helper/join";

const DraftEditor = ({ title, onChange, limit = 10000 }) => {
	const [value, setValue] = useState("");
	const quillRef = useRef(null);

	useEffect(() => {
		setValue((title || "").replace(/\n/g, "<br />"));
	}, [title]);

	useEffect(() => {
		onChange(value);
	}, [value]);

	const handleChange = (content) => {
		setValue(content);
		onChange(content);
	};

	return (
		<div className="w-full flex flex-col justify-end items-end relative">
			<ReactQuill
				ref={quillRef}
				className="w-full"
				theme="snow"
				value={value}
				onChange={handleChange}
			/>
			<div
				className={`text-[11px] ${
					getPlainText(value)?.length > limit && "text-red-600"
				} absolute -top-6`}
			>
				({getPlainText(value)?.length}/{limit})
			</div>
		</div>
	);
};

export default DraftEditor;
