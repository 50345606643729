import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DraftEditor from "../../../components/DraftEditor/index.js";
import {
	editSection,
	fetchCaseStudySection,
} from "../../../redux/caseStudiesSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { Upload } from "../../../components/upload/index.jsx";
import { getPlainText } from "../../../helper/join.js";
import { toastNotification } from "../../../services/notification-service.js";

export const EditSection = () => {
	const [draft, setDraft] = useState();
	const { sectionInfo, isLoading } = useSelector((store) => store.caseStudies);
	const [imagePreviews, setImagePreviews] = useState([]);
	const [data, setData] = useState({});
	const [error, setError] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id, faqId } = useParams();
	const limit = 10000;

	useEffect(() => {
		dispatch(fetchCaseStudySection(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (sectionInfo) {
			const updatedData = { ...sectionInfo, old_images: sectionInfo.images };
			delete updatedData.images;
			setData(updatedData);
			setDraft(updatedData?.section_content);
		} else {
			setData({});
		}
	}, [id, sectionInfo]);

	const handleFileChange = async (e) => {
		const files = e.target.files;

		if (files.length === 0) return; // No files selected

		let newImagePreviews = [...imagePreviews];
		let newImageData = [...(data?.images || [])];
		let exceedsSizeLimit = false;

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file.size / 1024 > 1024) {
				exceedsSizeLimit = true;
				break;
			}
			newImageData.push(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				newImagePreviews.push(reader.result);
				setImagePreviews([...newImagePreviews]);
			};
			reader.readAsDataURL(file);
		}

		if (exceedsSizeLimit) {
			setError("Size exceeded limit of 1024 KB!");
		} else {
			setData({ ...data, images: newImageData });
		}
	};

	const handleRemoveImage = (index) => {
		const newImageData = [...data.images];
		newImageData.splice(index, 1);
		const newImagePreviews = [...imagePreviews];
		newImagePreviews.splice(index, 1);
		setData({ ...data, images: newImageData });
		setImagePreviews(newImagePreviews);
	};

	const handleRemoveOldImage = (index) => {
		const newImageData = [...data.old_images];
		newImageData.splice(index, 1);
		setData({ ...data, old_images: newImageData });
	};

	const handleAdd = (e) => {
		e.preventDefault();
		if (getPlainText(draft)?.length > limit) {
			return toastNotification({status: "error", message: `Description must be under the limit of ${limit}`})
		}
		const formData = new FormData();
		const combinedData = { ...data, section_content: draft };

		if (
			!combinedData.section_name ||
			!combinedData.section_content ||
			combinedData.section_content === "<p></p>\n"
		) {
			const errorMessage = !combinedData.section_name
				? "Title is required field."
				: !combinedData.section_content ||
				  combinedData.section_content === "<p></p>\n"
				? "Content is required field."
				: "";
			setError(errorMessage);
			return;
		}

		data?.images?.forEach((image, index) => {
			formData.append(`images[${index}]`, image);
		});
		data?.old_images?.forEach((image, index) => {
			formData.append(`old_images[${index}]`, image.id);
		});
		delete combinedData.images;
		delete combinedData.old_images;

		if (!("images" in combinedData) || !("old_images" in combinedData)) {
			Object.keys(combinedData).forEach((key) => {
				formData.append(key, combinedData[key]);
			});
		}
		setError("");
		dispatch(editSection({ formData: { formData } }, navigate, faqId));
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Case Studies", link: "/contents/case-studies" },
					{ name: "Sections", link: `/contents/case-studies/${id}/sections` },
					{
						name: "Section Details",
						link: `/contents/case-studies/${id}/sections/${faqId}`,
					},
					{ name: "Edit Sections", link: `` },
				]}
			/>
			{isLoading?.fetchSection ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="bg-white px-4 pb-4 shadow-lg rounded-lg mx-[55px] p-6 mb-5">
					<form
						onSubmit={handleAdd}
						className="flex flex-col justify-center items-center mt-5"
					>
						<div className="w-full mx-auto">
							<div className="grid md:grid-cols-4 md:gap-20">
								<div className="relative md:col-start-1 md:col-span-2  w-full mb-5 group">
									<input
										type="text"
										name="name"
										id="name"
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										value={data?.section_name || ""}
										onChange={(e) =>
											setData({ ...data, section_name: e.target.value })
										}
									/>
									<label
										htmlFor="name"
										className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Name *
									</label>
								</div>
							</div>
							<div>
								<div className="text-sm text-orange-600 mb-3">
									Section Content *
								</div>
								<div className="relative  w-full mb-5 group border border-black">
									<DraftEditor
										onChange={setDraft}
										title={data?.section_content}
										limit={limit}
									/>
								</div>
							</div>
							<div className="w-full my-3">
								<label className="block mb-2 text-sm font-medium text-orange-600">
									Images
								</label>
								<div className="flex items-center gap-3">
									{(data.old_images || []).map((preview, index) => (
										<div className="relative mt-3">
											<img
												key={index}
												src={preview.path}
												alt={`Uploaded Preview ${index + 1}`}
												className="my-4 w-24 h-24 rounded mx-5"
											/>
											<IoMdClose
												className="cursor-pointer absolute left-3 top-0 h-5 w-5 rounded-full bg-gray-400"
												onClick={() => handleRemoveOldImage(index)}
											/>
										</div>
									))}
								</div>
								<Upload id={"case_sections"}>
									<input
									id="case_sections"
									type="file"
									accept=".jpeg, .jpg, .png, .gif, image/jpeg, image/jpg, image/png, image/gif"
									hidden="true"
									name="support_files"
									onChange={handleFileChange}
									multiple
									/>
              					</Upload>
								<div className="flex items-center gap-3">
									{(imagePreviews || []).map((preview, index) => (
										<div className="relative mt-3">
											<img
												key={index}
												src={preview}
												alt={`Uploaded Preview ${index + 1}`}
												className="my-4 w-24 h-24 rounded mx-5"
											/>
											<IoMdClose
												className="cursor-pointer absolute left-3 top-0 h-5 w-5 rounded-full bg-gray-400"
												onClick={() => handleRemoveImage(index)}
											/>
										</div>
									))}
								</div>
								<div></div>
							</div>
							<button className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out">
								{isLoading?.editSection ? (
									<div>
										<Loader h="5" w="5" color={"blue"} />
									</div>
								) : (
									"Update"
								)}
							</button>
							{error && <p className="my-2 text-red-500">{error}</p>}
						</div>
					</form>
				</div>
			)}
		</>
	);
};
